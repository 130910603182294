// import

import type {SignIn} from '@clerk/remix';
import type {ComponentProps} from 'react';

import {cram} from '@cat/rambo';
import {bodyFamily, orange, pinkZ, red, green, blue, black} from '@cat/tailwind-preset/vars';

import sty from './clerk.module.css';

// types

type ClerkTheme = ComponentProps<typeof SignIn>['appearance'];

// vars

const blockButtonStyles = cram(
  'h-3r px-1r',
  'rounded border-2 border-solid border-pinkZ-800',
  'text-1r tracking-normal',
  'hover:bg-orange-100 focus-visible:bg-orange-100 focus:shadow-none',
);

// export

export const clerkTheme: ClerkTheme = {
  layout: {
    logoPlacement: 'outside',
    shimmer: false,
  },
  // FIXME: Export style variables for use here...
  variables: {
    fontFamily: bodyFamily.join(', '),
    fontFamilyButtons: bodyFamily.join(', '),
    fontSize: '1.25rem',
    fontSmoothing: 'auto',
    fontWeight: {
      normal: 400,
      medium: 500,
      bold: 600,
    },

    borderRadius: '0.25rem',
    spacingUnit: '1rem',
    colorShimmer: undefined,
    shadowShimmer: undefined,
    colorAlphaShade: black,

    colorText: pinkZ[800],
    colorBackground: orange[25],

    // Blue instead of pink to handle _most_ cases...
    colorPrimary: blue[500],
    colorTextOnPrimaryBackground: orange[25],

    colorTextSecondary: pinkZ[800],

    colorDanger: red[500],
    colorSuccess: green[500],
    colorWarning: orange[500],

    colorInputText: pinkZ[800],
    colorInputBackground: orange[25],
  },
  elements: {
    rootBox: cram('children:!tracking-normal children:!accent-blue-400'),

    logoBox: cram('mb-1r2'),
    logoImage: cram('h-2r2 bg-orange-100'),

    card: cram(
      'px-2r4 py-2r4 w-b5 gap-1r4 border-pinkZ-800 border-2 shadow-pinkZ-800 shadow-hard-4 rounded',
    ),
    scrollBox: cram('overflow-visible rounded-none'),
    pageScrollBox: cram('overflow-visible p-0'),

    header: cram('gap-0'),
    headerTitle: cram('font-head'),
    headerSubtitle: cram('hidden'),

    main: cram('gap-2r4'),
    page: cram('gap-2r4'),

    socialButtons: cram('gap-1r'),
    socialButtonsBlockButton: blockButtonStyles,
    socialButtonsBlockButtonText: cram('font-medium'),

    alternativeMethods: cram('gap-1r'),
    alternativeMethodsBlockButton: blockButtonStyles,
    alternativeMethodsBlockButtonText: cram('font-medium'),

    dividerLine: cram('bg-pinkZ-200 h-2p rounded'),
    dividerText: cram('text-1r relative -top-1p'),

    form: cram('gap-1r'),
    formField: cram(sty['formField']),
    formFieldLabelRow: cram('mb-0'),
    formFieldInput: cram(
      'h-3r px-1r',
      'rounded border-2 border-pinkZ-800',
      'font-body text-1r placeholder:text-pinkZ-450',
      'focus:border-blue-550 focus:shadow-none',
    ),
    formFieldInputShowPasswordButton: cram(
      'text-pinkZ-300',
      'hover:text-pinkZ-450 active:text-pinkZ-600 focus:shadow-none',
    ),

    formButtonPrimary: cram(
      'h-3r px-1r bg-pink-300',
      'rounded border-2 border-solid border-pinkZ-800',
      'text-1r font-medium tracking-normal normal-case text-pinkZ-800',
      'hover:bg-pink-250 focus-visible:bg-pink-250 focus:shadow-none',
    ),

    footerActionText: cram('text-1r'),
    footerActionLink: cram(
      'text-blue-550 text-1r',
      'hover:text-blue-550 focus:shadow-none focus-visible:outline-2 focus-visible:outline-blue-550 focus-visible:outline-offset-2',
    ),
  },
};

export const clerkPageTheme: ClerkTheme = {
  ...clerkTheme,
  elements: {
    ...clerkTheme.elements,

    rootBox: cram(
      'w-full h-full',
      'children:!tracking-normal children:!accent-blue-400',
    ),

    card: cram('bg-transparent w-full max-w-auto border-0 shadow-none'),

    // navbar: cram('px-0 py-1r2 border-r-pinkZ-500 border-r-2'),
    navbar: cram('hidden'),
    navbarButton: cram(
      'tracking-normal font-medium',
      'focus:shadow-none focus-visible:bg-orangeZ-250',
    ),

    scrollBox: cram('overflow-visible'),
    // pageScrollBox: cram('overflow-visible py-1r2 pl-2r4 pr-0'),
    pageScrollBox: cram('overflow-visible py-1r2 px-0'),
    page: cram('gap-2r4'),
    profilePage: cram('gap-2r4'),
    header: cram('font-head leading-none gap-0'),

    profileSectionTitle: cram('border-b-2 border-b-pinkZ-200 pb-r4'),
    profileSectionTitleText: cram(
      'text-1r4 font-normal font-head leading-none',
    ),

    profileSectionPrimaryButton: cram(
      'text-pink-450 rounded tracking-normal',
      'hover:text-pink-800 hover:bg-pink-150',
      'focus-visible:text-pink-800 focus-visible:bg-pink-150 focus:shadow-none',
    ),
    accordionTriggerButton: cram(
      'rounded tracking-normal',
      'hover:bg-orange-150',
      'focus-visible:bg-orangeY-150 focus:shadow-none',
      sty['accordionTriggerButton'],
    ),

    profileSectionContent__profile: cram(sty['profileContent']),
    profileSectionContent__organizationProfile: cram(sty['profileContent']),

    profileSectionContent__organizationDanger: cram(sty['orgDanger']),

    tabPanel: cram(sty['tabPanel']),
    tabListContainer: cram('border-b-2 border-b-pinkZ-200'),
    tabButton: cram(
      'border-b-2 -mb-2p aria-selected:border-b-pinkZ-800 focus:shadow-none focus-visible:bg-orange-75',
    ),
    membersPageInviteButton: cram(
      'rounded tracking-normal normal-case font-medium text-r7 bg-blue-375 text-pinkZ-800',
      'hover:bg-blue-450',
    ),

    badge: cram(
      'bg-orange-25/75 border-2 border-blue-600 text-blue-600 text-r7 leading-none',
    ),

    formButtonReset: cram(
      'h-3r px-1r',
      'text-1r font-medium tracking-normal normal-case',
      'hover:bg-transparent hover:underline',
      'focus:shadow-none focus:underline',
    ),
  },
};
